import type { NextPage } from 'next'
import { IndexPageVariant3 } from '@/components/variants/pages/index-3'

type Props = {
  prices: Prices
}

type Prices = {
  annual: number,
  monthly: number
}
//
const Home: NextPage<Props> = ({ prices }) => {
  return (
    <>
      <IndexPageVariant3 prices={prices} />
    </>
  )
}

export async function getStaticProps() {
  const response = await fetch(`${process.env.APP_URL}/pricing`, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  })

  const json = await response.json()

  return {
    props: {
      prices: json
    }
  }
}

export default Home
